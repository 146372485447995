import * as React from "react";
import * as signalR from "@microsoft/signalr";

export const useEIP6963ProviderStore = (() => {
  const state = { providers: [] };
  const subscribe = (callback) => {
    const handle = (event) => {
      if (!state.providers.find(p => p.info.uuid === event.detail.info.uuid)) {
        state.providers = state.providers.concat([event.detail]);
        callback();
      }
    };
    window.addEventListener("eip6963:announceProvider", handle);
    window.dispatchEvent(new Event("eip6963:requestProvider"));
    return () => window.removeEventListener("eip6963:announceProvider", handle);
  }
  return () => React.useSyncExternalStore(subscribe, () => state.providers, () => state.providers);
})();

export const identityClientId = 'coinpayments-aphrodite';
export const identityBaseUrl = `${import.meta.env.REACT_APP_IDENTITY_URI}`;
export const apiBaseUrl = `${import.meta.env.REACT_APP_API_URL}`;
export const signalRBaseUrl = `${import.meta.env.REACT_APP_SIGNALR_URL}`

export const isProd = /coinpayments\.(com|net)/.exec(import.meta.env.REACT_APP_API_URL);
export const hasBalances = true;

export const debounced = {};
export const state = {};

export const uuid = () => {
  return ([1e7]+-1e3+-4e3+-8e3+-1e11).replace(/[018]/g, c =>
    ((c ^ (crypto.getRandomValues(new Uint8Array(1))[0] & 15)) >> c / 4).toString(16)
  );
};

export const authenticated = () => {
  return localStorage.oidc_token;
};

export const login = () => {
  localStorage.removeItem('oidc_token');
  localStorage.removeItem('oidc_idtoken');
  localStorage.removeItem('oidc_refreshtoken');
  localStorage.removeItem('oidc_expiration');
  const nonce = uuid();
  const redirect = `${identityBaseUrl}/connect/authorize?${new URLSearchParams({
    client_id: identityClientId,
    response_type: "id_token token",
    scope: "openid profile orion",
    redirect_uri: `${import.meta.env.REACT_APP_URL}/callback`,
    nonce: nonce
  })}`;
  window.location.href = redirect;
  return nonce;
};

export const register = () => {
  localStorage.removeItem('oidc_token');
  localStorage.removeItem('oidc_idtoken');
  localStorage.removeItem('oidc_refreshtoken');
  localStorage.removeItem('oidc_expiration');
  const nonce = uuid();
  const redirect = `${identityBaseUrl}/connect/authorize?${new URLSearchParams({
    client_id: identityClientId,
    response_type: "id_token token",
    scope: "openid profile orion",
    redirect_uri: `${import.meta.env.REACT_APP_URL}/callback`,
    nonce: nonce,
    signup: 1
  })}`;
  window.location.href = redirect;
  return nonce;
};

export const logout = () => {
  const redirect = `${identityBaseUrl}/connect/endsession?${new URLSearchParams({
    id_token_hint: localStorage.oidc_idtoken,
    post_logout_redirect_uri: `${import.meta.env.REACT_APP_URL}`
  })}`;
  localStorage.removeItem('oidc_token');
  localStorage.removeItem('oidc_idtoken');
  localStorage.removeItem('oidc_refreshtoken');
  localStorage.removeItem('oidc_expiration');
  window.location.href = redirect;
};

export const kycRedirect = (redirect) => {
  window.location.href = `${identityBaseUrl}/identity/kyc/verification?${new URLSearchParams({
    returnurl: `${import.meta.env.REACT_APP_URL}${redirect}`
  })}`;
};

export const callback = () => {
  const params = new URLSearchParams(window.location.hash.substr(1));
  const token = params.get('access_token');
  const expires = params.get('expires_in');
  const idtoken = params.get('id_token');
  const refreshtoken = params.get('refresh_token');
  if (token && expires) {
    localStorage.setItem('oidc_token', token);
    localStorage.setItem('oidc_idtoken', idtoken);
    localStorage.setItem('oidc_refreshtoken', refreshtoken);
    localStorage.setItem('oidc_expiration', (Date.now() / 1000) + (+expires));
    clearTimeout(state.refreshTimeout);
    state.refreshTimeout = setTimeout(() => {
      if ((Date.now() / 1000) > (+localStorage.oidc_expiration)) {
        document.body.dispatchEvent(new Event('unauthorized'));
      }
    }, ((+expires) * 1000) + 1);
    document.body.dispatchEvent(new Event('identity'));
    return true;
  }
};

export const token = (refresh) => {
  if (!refresh && (((Date.now() / 1000) + (10 * 60)) < (+localStorage.oidc_expiration))) {
    return Promise.resolve(localStorage.oidc_token);
  } else {
    return debounced.fetching || (debounced.fetching = fetch(`${identityBaseUrl}/connect/token`, {
      method: 'POST',
      body: JSON.stringify({
        client_id: identityClientId,
        grant_type: "extendsession",
        token: localStorage.oidc_refreshtoken
      }),
      headers: {
        'Strict-Transport-Security': `max-age=31536000`,
        'Content-Type': `application/json`,
        'Authorization': `Bearer ${localStorage.oidc_token}`
      }
    }).then(res => {
      if (res.status !== 200) {
        localStorage.removeItem('oidc_token');
        localStorage.removeItem('oidc_idtoken');
        localStorage.removeItem('oidc_refreshtoken');
        localStorage.removeItem('oidc_expiration');
      } else {
        return res.json();
      }
    }).finally(res => {
      delete debounced.fetching;
      const token = res?.access_token;
      if (token) {
        const expires = res?.expires_in;
        const idtoken = res?.id_token;
        localStorage.setItem('oidc_token', token);
        localStorage.setItem('oidc_idtoken', idtoken);
        localStorage.setItem('oidc_expiration', (Date.now() / 1000) + (+expires));
        clearTimeout(state.refreshTimeout);
        state.refreshTimeout = setTimeout(() => {
          if ((Date.now() / 1000) > (+localStorage.oidc_expiration)) {
            document.body.dispatchEvent(new Event('unauthorized'));
          }
        }, ((+expires) * 1000) + 1);
      }
      return token;
    }));
  }
};

export const refresh = () => {
  return debounced.refreshed || (debounced.refreshed = token().then(token => fetch(`${identityBaseUrl}/connect/userinfo`, {
    headers: {
      'Strict-Transport-Security': `max-age=31536000`,
      'Content-Type': `application/json`,
      'Authorization': `Bearer ${token}`
    }
  }).then(res => {
    if (res.status === 200) {
      return res.json();
    } else if (res.status === 401) {
      document.body.dispatchEvent(new Event('unauthorized'));
    }
  }).finally(res => {
    delete debounced.refreshed;
    if (res) {
      document.body.dispatchEvent(new Event('identity'));
    }
    if ((+localStorage.oidc_expiration)) {
      clearTimeout(state.refreshTimeout);
      state.refreshTimeout = setTimeout(() => {
        if ((Date.now() / 1000) > (+localStorage.oidc_expiration)) {
          document.body.dispatchEvent(new Event('unauthorized'));
        }
      }, (((+localStorage.oidc_expiration) * 1000) - Date.now()) + 1);
    }
    return res;
  })));
};

export const fetchManaged = (endpoint, options, raw, host, alert) => {
  return token().then((token) => {
    return token ? new Promise((resolve, reject) => {
      const error = (err, data) => {
        return new Promise((resolve, reject) => {
          if (err?.headers?.get('Content-Type')?.indexOf('json') >= 0) {
            Promise.resolve(data || err.json()).then(res => {
              const error = (typeof res === 'string' || res instanceof String)
                ? JSON.parse(res) : res;
              if (Object.values(error.errors || {}).length) {
                resolve(Object.values(error.errors).flatMap(e => e).map(e => {
                  document.body.dispatchEvent(new CustomEvent('error', {
                    detail: {
                      raw: e?.description || e
                    }
                  }));
                }));
              } else if (error.detail) {
                resolve(document.body.dispatchEvent(new CustomEvent('error', {
                  detail: {
                    raw: error.detail
                  }
                })));
              } else {
                reject();
              }
            }).catch(reject);
          } else {
            reject();
          }
        }).catch(() => {
          document.body.dispatchEvent(new CustomEvent('error', {
            detail: {
              template: 'errors.fetch',
              alert: alert,
              params: {
                '<endpoint>': endpoint
              }
            }
          }));
        });
      };
      fetch(`${host || apiBaseUrl}${endpoint}`, {
        ...options,
        redirect: 'manual',
        headers: {
          ...options?.headers,
          'Strict-Transport-Security': `max-age=31536000`,
          'Authorization': `Bearer ${token}`
        }
      }).then(res => {
        if (res.status === 401) {
          document.body.dispatchEvent(new Event('unauthorized'));
          reject(res);
        } else if (res.status === 451) {
          res.json().then(data => {
            document.body.dispatchEvent(new CustomEvent('inappropriateinstanceurl', {
              detail: data
            }));
            reject(res);
          });
        } else if ((res.status >= 400) || (res.status < 200)) {
          const data = Promise.resolve(
            (raw && ((res.headers?.get('Content-Type')?.indexOf('json') < 0)))
            ? res : res.json());
          Promise.resolve(data).then(reject).catch(reject);
          if (!options?.silent && ((res.status === 404) || (res.status === 400))) {
            error(res, data);
          }
        } else {
          Promise.resolve(
            (raw || ((res.headers?.get('Content-Type')?.indexOf('json') < 0)))
            ? res : res.json()).then(resolve).catch(reject);
        }
      }).catch(err => {
        if (!options?.silent && (err.code !== err.ABORT_ERR)) {
          error(err);
        }
        reject(err);
      });
    }) : new Promise((resolveIgnored, reject) => {
      document.body.dispatchEvent(new Event('unauthorized'));
      reject();
    });
  })
};

export const fetchDebounced = (endpoint, options, raw, host) => {
  return debounced[endpoint] || (debounced[endpoint] = fetchManaged(endpoint, options, raw, host).then(res => {
    delete debounced[endpoint];
    return res;
  }));
};

export const fetchAllPagesDebounced = (endpoint, options, raw, host) => {
  return debounced[endpoint] || (debounced[endpoint] = new Promise((resolve, reject) => {
    const fetch = (acc, after) => {
      fetchManaged(`${endpoint}?${new URLSearchParams({after: after || ''})}`, options, raw, host).then(res => {
        const items = acc.concat(res.items);
        if ((res.items.length === res.paging.limit) && res.paging.cursors.after) {
          fetch(items, res.paging.cursors.after);
        } else {
          resolve({
            items: items,
            paging: {}
          });
        }
      }).catch(reject);
    };
    fetch([]);
  }).then(res => {
    delete debounced[endpoint];
    return res;
  }));
};

export const getSocketConnection = () => {
  if (!localStorage.oidc_token || state.socket) {
    return state.socket;
  }

  const connection = new signalR.HubConnectionBuilder()
    .withUrl(signalRBaseUrl, {
      accessTokenFactory: token
    })
    .withAutomaticReconnect()
    .build();

  const listeners = {};
  const events = {
    on: (event, handler) => listeners[event] = new Set([...(listeners[event] || [])].concat(handler)),
    off: (event, handler) => listeners[event] = new Set([...(listeners[event] || [])].filter(h => h !== handler)),
    dispatch: (event, payload) => [...(listeners[event] || [])].forEach(handler => handler(payload))
  };

  ['transactionCompletedEvent',
   'transferReceived',
   'transactionConfirmed',
   'invoiceStateChanged',
   'invoicePaymentConfirmationChanged'].forEach(event =>
    connection.on(event, (payload) => {
      events.dispatch(event, payload);
    }));

  return state.socket = {
    connection: connection,
    started: connection.start(),
    events: events
  };
};