import * as React from "react";

import * as Client from "./client";

const internalRequests = {};
const staticRefs = {};

const trackedStates = new Map();
const atomStates = new Map();

export const useLens = (path, initial, [toState, fromState]=[null, null], dependencies=[]) => {
  const initialRef = React.useRef(initial);
  const pathRef = React.useRef(path);
  const keys = React.useMemo(() => (pathRef.current instanceof Array) ? pathRef.current : [pathRef.current], [pathRef.current]);
  const fromStateCached = React.useMemo(() => {
    const cache = {};
    return (state) => {
      if (state !== cache.input) {
        cache.input = state;
        cache.output = fromState(state);
      }
      return cache.output;
    };
  }, path.concat(dependencies || []));
  const get = React.useCallback((root) => {
    return keys.reduce((acc, p, idx) => {
      if (idx < (keys.length - 1)) {
        return acc[p] || {};
      } else {
        const ret = acc[p] !== undefined ? acc[p] : initialRef.current;
        return fromState ? fromStateCached(ret) : ret;
      }
    }, root);
  }, [fromStateCached].concat(dependencies || []));
  const set = React.useCallback((root, value) => {
    const snapshot = (root instanceof Array) ? [].concat(root) : Object.assign({}, root);
    const updated = keys.reduce((acc, p, idx) => {
      if (idx < (keys.length - 1)) {
        const node = acc[p] || {};
        return acc[p] = (node instanceof Array) ? [].concat(node) : Object.assign({}, node);
      } else {
        const current = acc[p] !== undefined ? acc[p] : initialRef.current;
        const final = (value instanceof Function) ? value(fromState ? fromStateCached(current) : current) : value;
        acc[p] = toState ? toState(final, current) : final;
        return snapshot;
      }
    }, snapshot);
    return updated;
  }, [fromStateCached].concat(dependencies || []));
  return [get, set];
};

export const useStateLens = (path, [state, setState], initial, [toState, fromState]=[null, null], dependencies=[]) => {
  const [get, set] = useLens(path, initial, [toState, fromState], dependencies);
  const [value, setter] = React.useMemo(() => {
    const value = get(state);
    const setter = (update) => {
      setState(set(state, update));
    };
    return [value, setter];
  }, [path, get, set, state, setState]);
  return [value, setter];
};

export const useStateLensValue = (path, state, initial, [toState, fromState]=[null, null], dependencies=[]) => {
  const [value, setterIgnored] = useStateLens(path, state, initial, [toState, fromState], dependencies);
  return value;
};

export const useStateLensSetter = (path, state, initial, [toState, fromState]=[null, null], dependencies=[]) => {
  const [valueIgnored, setter] = useStateLens(path, state, initial, [toState, fromState], dependencies);
  return setter;
};

export const useTrackedState = (initial) => {
  const [state, setState] = React.useState(initial);
  const persisted = React.useRef({
    value: state,
    pending: state,
    listeners: new Set(),
    callback: null,
    initialized: false
  });
  const setStateWrapper = React.useCallback((update) => {
    const state = (update instanceof Function) ? update(persisted.current.pending) : update;
    if (persisted.current.pending !== state) {
      persisted.current.pending = state;
      const persist = () => {
        persisted.current.value = persisted.current.pending;
        persisted.current.listeners?.forEach(listener => listener(persisted.current.value));
        setState(persisted.current.value);
      };
      if (!persisted.initialized) {
        clearTimeout(persisted.current.callback);
        persisted.current.callback = setTimeout(() => {
          persist();
          persisted.initialized = true;
        });
      } else {
        persist();
      }
    }
  }, [setState]);
  if (!trackedStates.has(setStateWrapper)) {
    trackedStates.set(setStateWrapper, persisted.current);
  }
  return [state, setStateWrapper, persisted];
};

export const useTrackedStateLens = (path, [state, setState], initial, [toState, fromState]=[null, null], dependencies=[]) => {
  const persisted = trackedStates.get(setState);
  if (!persisted) {
    return useStateLens(path, [state, setState], initial, [toState, fromState], dependencies);
  }
  const [get, set] = useLens(path, initial, [toState, fromState], dependencies);
  const [value, setValue] = React.useState(get(persisted.value));
  const internal = React.useRef({
    initialized: false
  });
  React.useEffect(() => {
    const timeout = {};
    const listener = (update) => {
      const updated = get(update);
      if (updated !== value) {
        if (!internal.current.initialized) {
          clearTimeout(timeout.callback);
          timeout.callback = setTimeout(() => {
            internal.current.initialized = true;
            setValue(updated);
          });
        } else {
          setValue(updated);
        }
      }
    };
    persisted.listeners.add(listener);
    return () => {
      persisted.listeners.delete(listener);
    };
  }, [get, state]);
  const setter = React.useMemo(() => (update) => {
    setState(state => set(state, update));
  }, [set, setState]);
  return [value, setter];
};

export const useTrackedStateLensValue = (path, [state, setState], initial, [toState, fromState]=[null, null], dependencies=[]) => {
  const [value, setterIgnored] = useTrackedStateLens(path, [state, setState], initial, [toState, fromState], dependencies);
  return value;
};

export const useTrackedStateLensSetter = (path, [state, setState], initial, [toState, fromState]=[null, null], dependencies=[]) => {
  const [valueIgnored, setter] = useTrackedStateLens(path, [state, setState], initial, [toState, fromState], dependencies);
  return setter;
};

export const useAtom = (key, initial) => {
  const persisted = atomStates.set(key, atomStates.get(key) || {
    value: initial,
    listeners: new Set()
  }).get(key);
  const [state, setState] = React.useState(persisted.value);
  React.useEffect(() => {
    persisted.listeners.add(setState);
    setState(persisted.value);
    return () => {
      persisted.listeners.delete(setState);
      setTimeout(() => {
        if (persisted.listeners.size === 0) {
          atomStates.delete(key);
        }
      });
    }
  }, [key, setState]);
  const setter = React.useMemo(() => (update) => {
    persisted.value = (update instanceof Function) ? update(persisted.value) : update;
    persisted.listeners.forEach(listener => listener(persisted.value));
  }, [key, setState]);
  return [state, setter, persisted];
};

export const useAtomValue = (key, initial) => {
  const [value, setterIgnored] = useAtom(key, initial);
  return value;
};

export const useAtomSetter = (key, initial) => {
  const [valueIgnored, setter] = useAtom(key, initial);
  return setter;
};

export const useAtomLens = (path, key, initial, [toState, fromState]=[null, null], dependencies=[]) => {
  const [get, set] = useLens(path, initial, [toState, fromState], dependencies);
  const persisted = atomStates.get(key);
  const [value, setValue] = React.useState(get(persisted.value));
  React.useEffect(() => {
    const listener = (update) => {
      const updated = get(update);
      if (updated !== value) {
        setValue(updated);
      }
    };
    persisted.listeners.add(listener);
    return () => {
      persisted.listeners.delete(listener);
    };
  }, [get]);
  const setter = React.useMemo(() => (update) => {
    persisted.value = set(persisted.value, update)
    persisted.listeners.forEach(listener => listener(persisted.value));
  }, [set]);
  return [value, setter];
};

export const useAtomLensValue = (path, key, initial, [toState, fromState]=[null, null], dependencies=[]) => {
  const [value, setterIgnored] = useAtomLens(path, key, initial, [toState, fromState], dependencies);
  return value;
};

export const useAtomLensSetter = (path, key, initial, [toState, fromState]=[null, null], dependencies=[]) => {
  const [valueIgnored, setter] = useAtomLens(path, key, initial, [toState, fromState], dependencies);
  return setter;
};

export const useGlobalEffect = (key, effect, dependencies) => {
  const [effectGroupIgnored, setEffectGroup, effectGroupPersisted] = useAtom(`globalEffect:${key}`, {
    dependencies: []
  });

  React.useEffect(() => {
    if (dependencies.filter((d, idx) => d !== effectGroupPersisted.value.dependencies[idx]).length) {
      setEffectGroup(effectGroup => ({
        ...effectGroup,
        dependencies: dependencies
      }));
      return effect();
    }
  }, dependencies);
};

export const useInputLensTransform = () => {
  return [
    (value) => value?.input !== undefined ? value?.input : '',
    (state) => {
      return {
        input: state
      };
    }
  ];
};

export const useOptionValueLensTransform = ({options}) => {
  return [
    (value) => value?.option?.value !== undefined ? value?.option?.value : '',
    (state) => {
      return {
        option: options.find(opt => opt.value === state) || {}
      };
    }
  ];
};

export const useDateLensTransform = () => {
  return [
    (value) => value?.date ? value?.date?.toISOString() : null,
    (state) => {
      const date = state ? new Date(state) : null;
      return {
        input: date ? `${date.getDate()} ${date.toDateString().split(' ')[1]} ${date.getFullYear()}` : null,
        date: date
      };
    }
  ];
};

export const useStateRef = (init) => {
  const id = React.useRef(Client.uuid()).current;
  const [value, setValue] = useAtom(id, init);
  React.useEffect(() => {
    setValue(init);
  }, []);
  return [value, setValue, id];
};

export const useStateRefInstance = (stateRef) => {
  return useAtom(stateRef);
};

export const useLoadGroup = (key, {loaders, dependencies, merge, empty, interval}) => {
  const [loadGroup, setLoadGroup, loadGroupPersisted] = useAtom(`loadGroup:${key}`, {
    dependencies: null,
    loaderDependencies: null,
    loaderResults: null,
    loadedResults: null,
    result: empty || {},
    resultValues: null,
    loading: true,
    timeout: null
  });
  const [dependencyValues, dependencyLoaders, dependencyUpdaters, dependenciesLoading] = (dependencies || []).reduce(
    ([values, loaders, updaters, loading], dependency) => {
      const [value, loader, updater] = dependency();
      return [values.concat([value]), loaders.concat([loader]), updaters.concat([updater]), loading || loader];
    }, [[], [], [], false]);

  const updateLoadGroup = (update, silent) => {
    return new Promise((resolve, reject) => {
      const loadGroup = loadGroupPersisted.value;
      const loaderGroup = (loaders || []).map((loader, idx) => loader.apply(null, [
        (typeof update === 'function' ? update(loadGroup.result) : update),
        (loadGroup.loaderResults || [])[idx]
      ].concat(dependencyValues).concat([loadGroup])));

      if (loaderGroup.filter((p, idx) => p && (!!p) !== (!!(staticRefs[key] || [])[idx])).length) {
        setLoadGroup(loadGroup => ({
          ...loadGroup,
          previousLoadGroup: loadGroup,
          loaderDependencies: dependencyValues,
          loading: true,
          silent: silent
        }));

        staticRefs[key] = loaderGroup;
        Promise.all(loaderGroup.map(
          (loader, idx) => loader
            ? (typeof loader === 'function' ? loader() : loader)
            : (loadGroup.loaders || [])[idx]))
          .then(results => {
            if (results.filter(r => !r).length) {
              setLoadGroup(loadGroup => ({
                ...loadGroup.previousLoadGroup,
                ...loadGroup,
                loading: false
              }));

              delete staticRefs[key];

              reject();
            } else {
              setLoadGroup(loadGroup => ({
                ...loadGroup,
                loaderResults: results
              }));

              delete staticRefs[key];

              resolve();
            }
          }).catch(() => {
            setLoadGroup(loadGroup => ({
              ...loadGroup,
              loading: false
            }));

            delete staticRefs[key];

            reject();
          });
      } else if (((loaderGroup.length === 0) || (loadGroup.loaderResults !== loadGroup.loadedResults) || (!loadGroup.loading && dependencyValues.filter((v, idx) => v !== (loadGroup.dependencies || [])[idx]).length)) && (dependencyLoaders.filter(loading => !loading).length === dependencyLoaders.length)) {
        const values = (loadGroup.loaderResults || []).concat(dependencyValues);
        const merged = merge ? merge.apply(null, values.concat([loadGroup.result]).concat(loadGroup.resultValues)) : values.length === 1 ? values[0] : values;

        setLoadGroup(loadGroup => ({
          ...loadGroup,
          dependencies: dependencyValues,
          loadedResults: loadGroup.loaderResults,
          result: merged,
          resultValues: values,
          loading: false
        }));

        resolve();
      } else {
        resolve();
      }
    });
  };

  useGlobalEffect(key, () => {
    updateLoadGroup();
    if (interval) {
      setLoadGroup(loadGroup => {
        clearTimeout(loadGroup.timeout);
        return {
          ...loadGroup,
          timeout: setTimeout(() =>  updateLoadGroup({}, true), interval)
        }
      });
    }
  }, [loadGroup.loaderResults].concat(dependencyValues));

  const [forceLoading, setForceLoading] = React.useState();
  const forceUpdate = (params, silent) => {
    if (!silent) {
      setForceLoading(true);
    }
    return Promise.all(dependencyUpdaters.map(update => update({}, true)))
      .then(() => updateLoadGroup(params || {}, silent))
      .finally(() => {
        delete forceUpdate.current;
        setForceLoading(false);
      });
  };

  return [loadGroup.result, (loadGroup.loading && !loadGroup.silent) || dependenciesLoading || forceLoading, forceUpdate];
};

const useEndpointLoadGroup = (key, {loader, endpoint, initial}) => {
  return useLoadGroup(key, {
    loaders: [(update, value) => {
      if (update && Object.keys(update).length) {
        return () => Client.fetchManaged(endpoint.path || endpoint, {
          method: 'PUT',
          body: JSON.stringify(endpoint.transform ? endpoint.transform(update) : update),
          headers: {
            'Content-Type': 'text/json'
          }
        }, true, initial).then(() => {
          return ({
            ...value,
            ...update
          });
        });
      } else if (!value || update) {
        return loader;
      }
    }]
  });
};

export const fetchPreferences = () => {
  return internalRequests.preferences ||
    (internalRequests.preferences = Client.fetchDebounced(`/api/v1/preferences`).then(res => {
      return res;
    }).catch(() => {
      return {};
    }).finally(res => {
      delete internalRequests.preferences;
      return res;
    }));
};

export const usePreferences = () => {
  return useEndpointLoadGroup('preferences', {
    loader: fetchPreferences,
    endpoint: `/api/v1/preferences`
  });
};

export const fetchCurrencyBalances = () => {
  return (!Client.hasBalances) ? Promise.resolve({}) : internalRequests.currencyBalances ||
    (internalRequests.currencyBalances = Client.fetchDebounced(`/api/v1/currencies/balances`).then(res => {
      return res.reduce((acc, {currency, balance}) => ({
        ...acc,
        [currency.id]: {
          ...currency,
          balance: Object.entries(balance).reduce((acc, [key, value]) => ({
            ...acc,
            [key]: {
              value: ((value?.value !== undefined) ? value.value : value).split('.')[0]
            }
          }), {})
        }
      }), {});
    }).catch(() => {
      return {};
    }).finally(res => {
      delete internalRequests.currencyBalances;
      return res;
    }));
};

export const useCurrencyBalances = () => {
  return useLoadGroup('currencyBalances', {
    loaders: [(update, balances) => (update || !balances)
      ? fetchCurrencyBalances.bind(null, balances)
      : null]
  });
};

export const fetchCurrencyConversions = (existing) => {
  return internalRequests.currencyConversions ||
    (internalRequests.currencyConversions = Client.fetchDebounced(`/api/v1/currencies/conversions`).then(res => {
      return res.reduce((acc, {from, to}) => ({
        ...acc,
        [from]: (acc[from] || new Set()).add(to)
      }), existing || {});
    }).catch(() => {
      return {};
    }).finally(res => {
      delete internalRequests.currencyConversions;
      return res;
    }));
};

export const useCurrencyConversions = () => {
  return useLoadGroup('currencyConversions', {
    loaders: [(update, conversions) => (update || !conversions)
      ? fetchCurrencyConversions.bind(null, conversions)
      : null]
  });
};

export const fetchCurrencies = () => {
  return internalRequests.currencies ||
    (internalRequests.currencies = Client.fetchDebounced(`/api/v1/currencies`).then(res => {
      return res;
    }).catch(() => {
      return [];
    }).finally(res => {
      delete internalRequests.currencies;
      return res;
    }));
};

export const useCurrencies = () => {
  return useLoadGroup('currencies', {
    loaders: [(update, currencies) => (!currencies)
      ? fetchCurrencies
      : null],
    dependencies: [useCurrencyConversions],
    merge: (currencies, conversions, previous, previousCurrenciesIgnored, previousConversions) => {
      const lookup = currencies.reduce((acc, c) => ({
        ...acc,
        [c.symbol]: c
      }), {});
      return (Object.keys(previousConversions || {}).length ? previous : null) || currencies.reduce((acc, c) => ({
        ...acc,
        [c.id]: {
          ...c,
          symbol: c.symbol.replace(/CPS_/, '').replace(/_TESTNET/, '').replace(/_TEST/, '').replace(/BSC_/, ''),
          conversions: conversions[c.id] || new Set(),
          feeCurrencies: {
            // 'EVERY.ERC20': ['ETH'].reduce((acc, symbol) => acc.add(lookup[symbol].id), new Set()),
            // 'EVERY.BASE': ['ETH.BASE'].reduce((acc, symbol) => acc.add(lookup[symbol].id), new Set())
          }[c.symbol] || new Set([c.id])
        }
      }), {});
    }
  });
};

export const fetchAccounts = () => {
  return internalRequests.accounts ||
    (internalRequests.accounts = Client.fetchDebounced(`/api/v1/wallets`).then(res => {
      return res.items;
    }).catch(() => {
      return [];
    }).finally(res => {
      delete internalRequests.accounts;
      return res;
    }));
};

export const useAccounts = () => {
  return useLoadGroup('accounts', {
    loaders: [(update, accounts, currencies, preferences) =>
      (update || !accounts || (preferences?.nativeCurrencyId && accounts?.length && preferences?.nativeCurrencyId !== accounts[0].confirmedNativeBalance.currencyId))
        ? fetchAccounts
        : null],
    dependencies: [useCurrencies, usePreferences],
    merge: (accounts, currencies) => {
      const now = new Date();
      return accounts.map(account => {
        const currencyId = [account.currencyId, account.contractAddress].filter(p => p).join(':');
        return {
          ...account,
          updated: now,
          currencyId: currencyId,
          currency: currencies[currencyId]
        };
      }).filter(a => a.currency);
    },
    empty: []
  });
};

export const useWallets = () => {
  return useLoadGroup('wallets', {
    dependencies: [useAccounts],
    merge: (accounts) => {
      return accounts.sort((a, b) => a.walletId.localeCompare(b.walletId)).reduce((acc, account) => {
        const confirmedToken = account.contractAddress ? account.confirmedTokens.find(token => token.contractAddress === account.contractAddress) : null;
        const unconfirmedToken = account.contractAddress ? account.unconfirmedTokens.find(token => token.contractAddress === account.contractAddress) : null;
        return {
          ...acc,
          [account.currencyId]: {
            ...account,
            label: account.currency.name,
            confirmedBalance: account.contractAddress ? {
              currencyId: account.currencyId,
              value: (confirmedToken?.value || 0).toString().split('.')[0]
            } : account.confirmedBalance,
            confirmedNativeBalance: account.contractAddress ? {
              currencyId: account.confirmedNativeBalance.currencyId,
              value: (confirmedToken?.nativeValue || 0).toString().split('.')[0]
            } : account.confirmedNativeBalance,
            unconfirmedBalance: account.contractAddress ? {
              currencyId: account.currencyId,
              value: (unconfirmedToken?.value || 0).toString().split('.')[0]
            } : account.unconfirmedBalance,
            unconfirmedNativeBalance: account.contractAddress ? {
              currencyId: account.unconfirmedNativeBalance.currencyId,
              value: (unconfirmedToken?.nativeValue || 0).toString().split('.')[0]
            } : account.unconfirmedNativeBalance
          }
        };
      }, {});
    }
  });
};

export const fetchMerchantProfile = () => {
  return internalRequests.merchantProfile ||
    (internalRequests.merchantProfile = Client.fetchDebounced(`/api/v1/merchant/profile`).then(res => {
      return res;
    }).catch(() => {
      return {};
    }).finally(res => {
      delete internalRequests.merchantProfile;
      return res;
    }));
};

export const useMerchantProfile = () => {
  return useEndpointLoadGroup('merchantProfile', {
    loader: fetchMerchantProfile,
    endpoint: `/api/v1/merchant/profile`
  });
};

export const fetchUserProfile = () => {
  return internalRequests.userProfile ||
    (internalRequests.userProfile = Client.fetchDebounced(`/api/v1/user/profile`, null, false, Client.identityBaseUrl).then(res => {
      return res;
    }).catch(() => {
      return {};
    }).finally(res => {
      delete internalRequests.userProfile;
      return res;
    }));
};

export const useUserProfile = () => {
  return useEndpointLoadGroup('userProfile', {
    loader: fetchUserProfile,
    endpoint: `/api/v1/user/profile`,
    initial: Client.identityBaseUrl
  });
};

export const fetchSettings = () => {
  return internalRequests.settings ||
    (internalRequests.settings = Client.fetchDebounced(`/api/v1/settings/all`, {
      method: 'GET',
      headers: {
        'Content-Type': 'text/json'
      }
    }).then(res => {
      return res.reduce((acc, {key, value}) => (key in acc ? {
        ...acc,
        [key]: typeof acc[key] === 'string' ? value : JSON.parse(value)
      } : acc), {
        Loaded: true,
        UtcKey: 'Europe/London',
        AccountSignedNotification: true,
        TermsAcceptanceTimestamp: null,
        PrivacyPolicyAcceptanceTimestamp: null
      });
    }).catch(() => {
      return {};
    }).finally(res => {
      delete internalRequests.settings;
      return res;
    }));
};

export const useSettings = () => {
  return useEndpointLoadGroup('settings', {
    loader: fetchSettings,
    endpoint: {
      path: `/api/v1/settings`,
      transform: (update) => Object.entries(update).map(([key, value]) => ({
        key: key,
        value: typeof value === 'string' ? value : JSON.stringify(value)
      }))[0]
    }
  });
};

export const fetchVerificationSettings = () => {
  return internalRequests.verificationSettings ||
    (internalRequests.verificationSettings = Client.fetchDebounced(`/api/v1/user/2fa`, null, false, Client.identityBaseUrl).then(res => {
      return res;
    }).catch(() => {
      return {};
    }).finally(res => {
      delete internalRequests.verificationSettings;
      return res;
    }));
};

export const useVerificationSettings = () => {
  return useEndpointLoadGroup('verificationSettings', {
    loader: fetchVerificationSettings,
    endpoint: `/api/v1/user/2fa`
  });
};

export const fetchNotifications = (language) => {
  return internalRequests.notifications ||
    (internalRequests.notifications = Client.fetchDebounced(`/api/v1/notifications?${new URLSearchParams({
      languageCode: language || 'EN'
    })}`).then(res => {
      return res.items.map(i => ({
        ...i,
        publishedDate: new Date(i.publishedDate)
      }));
    }).catch(() => {
      return [];
    }).finally(res => {
      delete internalRequests.notifications;
      return res;
    }));
};

export const useNotifications = () => {
  return useLoadGroup('notifications', {
    loaders: [(update, notifications, preferences, loadGroup) => {
      if (preferences?.language && (update || ((loadGroup?.loaderDependencies || [])[0]?.language !== preferences?.language))) {
        return fetchNotifications.bind(null, preferences?.language);
      }
    }],
    dependencies: [usePreferences],
    merge: (notifications) => notifications,
    empty: []
  });
};

export const fetchEnvFeatures = () => {
  return internalRequests.envFeatures ||
    (internalRequests.envFeatures = Client.fetchDebounced(`/api/v1/features`).then(res => {
      return res;
    }).catch(() => {
      return {};
    }).finally(res => {
      delete internalRequests.envFeatures;
      return res;
    }));
};

export const useEnvFeatures = () => {
  return useEndpointLoadGroup('features', {
    loader: fetchEnvFeatures,
    endpoint: `/api/v1/features`
  });
};

export const fetchEnvConfig = () => {
  return internalRequests.envConfig ||
    (internalRequests.envConfig = Client.fetchDebounced(`/api/v1/env`).then(res => {
      return res;
    }).catch(() => {
      return {};
    }).finally(res => {
      delete internalRequests.envConfig;
      return res;
    }));
};

export const useEnvConfig = () => {
  return useLoadGroup('env', {
    loaders: [(update, envConfig) => (update || !envConfig) ? fetchEnvConfig : null],
    dependencies: [useEnvFeatures],
    merge: (env, features) => {
      return {
        ...env,
        featuresEnabled: features
      };
    }
  });
};

export const fetchKycStatus = () => {
  return internalRequests.kycStatus ||
    (internalRequests.kycStatus = Client.fetchDebounced(`/api/v1/user/kyc/complete`).then(res => {
      return {
        ...res,
        raw: res,
        hasCompletedKyc: res.hasCompletedKyc && !res.showPopup
      };
    }).catch((err) => {
      return { hasCompletedKyc: true };
    }).finally(res => {
      delete internalRequests.kycStatus;
      return res;
    }));
};

export const useKycStatus = () => {
  return useEndpointLoadGroup('kycStatus', {
    loader: fetchKycStatus,
    endpoint: `api/v1/user/kyc/complete`
  });
};

export const fetchPersonalTiers = () => {
  return internalRequests.personalTiers ||
    (internalRequests.personalTiers = Client.fetchDebounced(`/api/v1/fee/tiers-personal`).then(res => {
      return res;
    }).catch(() => {
      return [];
    }).finally(res => {
      delete internalRequests.personalTiers;
      return res;
    }));
};

export const fetchCommercialTiers = () => {
  return internalRequests.commercialTiers ||
    (internalRequests.commercialTiers = Client.fetchDebounced(`/api/v1/fee/tiers-commercial`).then(res => {
      return res;
    }).catch(() => {
      return [];
    }).finally(res => {
      delete internalRequests.commercialTiers;
      return res;
    }));
};

export const fetchMonthVolume = () => {
  return internalRequests.monthVolume ||
    (internalRequests.monthVolume = Client.fetchDebounced(`/api/v1/user/month-volume`).then(res => {
      return res;
    }).catch(() => {
      return {};
    }).finally(res => {
      delete internalRequests.monthVolume;
      return res;
    }));
};

export const useMonthVolume = () => {
  return useLoadGroup('monthVolume', {
    loaders: [
      (update, volume) =>
        (update || !volume)
          ? fetchMonthVolume
          : null,
      (update, personalTiers) =>
        (update || !personalTiers)
          ? fetchPersonalTiers
          : null,
      (update, commercialTiers) =>
        (update || !commercialTiers)
          ? fetchCommercialTiers
          : null],
    dependencies: [],
    merge: (volume, personalTiers, commercialTiers) => {
      const commercialTier = commercialTiers.map((tier, idx) => ({...tier, idx: idx + 1})).filter(tier => tier.from <= volume.commercial && (tier.to === null || tier.to >= volume.commercial));
      const personalTier = personalTiers.map((tier, idx) => ({...tier, idx: idx + 1})).filter(tier => tier.from <= volume.personal && (tier.to === null || tier.to >= volume.commercial));
      return {
        commercial: {
          volume: volume.commercial,
          tier: commercialTier[0]
        },
        personal: {
          volume: volume.personal,
          tier: personalTier[0]
        }
      };
    },
    empty: {}
  });
};