import React, { useRef, useEffect } from 'react';

const WalletShader = () => {
  const containerRef = useRef(null);
  const canvasRef = useRef(null);
  
  useEffect(() => {
    const observing = containerRef.current;
    if (!observing) {
      return;
    }

    const canvas = canvasRef.current;
    const gl = canvas.getContext('webgl');
    
    if (!gl) {
      console.error('WebGL not supported');
      return;
    }
    
    // Set canvas size
    const setCanvasSize = () => {
      const dpr = window.devicePixelRatio || 1;
      const displayWidth = Math.floor(canvas.clientWidth * dpr);
      const displayHeight = Math.floor(canvas.clientHeight * dpr);
      
      if (canvas.width !== displayWidth || canvas.height !== displayHeight) {
        canvas.width = displayWidth;
        canvas.height = displayHeight;
        gl.viewport(0, 0, canvas.width, canvas.height);
      }
    };
    
    setCanvasSize();

    const resize = new ResizeObserver(setCanvasSize);
    resize.observe(observing);
    window.addEventListener('resize', setCanvasSize);
    
    // Vertex shader
    const vertexShaderSource = `
      attribute vec4 aVertexPosition;
      attribute vec2 aTextureCoord;
      
      varying highp vec2 vTextureCoord;
      
      void main() {
        gl_Position = aVertexPosition;
        vTextureCoord = aTextureCoord;
      }
    `;
    
    // Fragment shader (converted from the provided shader with fixes)
    const fragmentShaderSource = `
      precision highp float;
      varying highp vec2 vTextureCoord;
      uniform vec2 iResolution;
      uniform float iTime;
      
      #define SCALE 20.0
      #define COLOR vec3(.2,.4,.8)
      #define BACK vec3(0.2)
      #define SHINE 0.15
      #define CURVE 0.3
      #define CHECK_SPEED 8.0
      #define CHECK_WIDTH 1.5
      #define CHECK_GRAD 0.2
      #define CHECK_START 0.5
      #define SHIM_SPEED 0.7
      #define SHIM_START 0.2
      #define SHIM_END   1.0
      #define STAR_SIZE 0.03
      #define STAR_RAD 0.2
      #define STAR_SPIN 1.0
      #define STAR_EXP 0.3
      #define STAR_FREQ 0.6
      #define STAR_START 0.8
      #define STAR_END 0.9
      #define T  6.2831853
      #define HT 3.1415926
      #define QT 1.5707963
      
      // Custom tanh function since it's not available in GLSL
      float mytanh(float x) {
        float ex = exp(2.0 * x);
        return (ex - 1.0) / (ex + 1.0);
      }
      
      // Replacement for fwidth - approximating partial derivatives
      vec2 myfwidth(vec2 p) {
        // Small delta for derivative approximation
        vec2 h = vec2(0.05, 0.05);
        return abs(p - p + h);
      }
      
      void main() {
        vec2 res = iResolution.xy;
        vec2 fragCoord = vTextureCoord * res;
        vec2 uv = (fragCoord*2.0-res)/res.y;
        
        // Background transparent (changed from original)
        vec3 col = vec3(0.0);
        float alpha = 0.0;
        
        // Wallet skew (dynamic)
        float SKEW = 0.5*exp(0.2*cos(0.3*iTime+uv.x));
        
        // Skewed coordinates
        vec2 p = SCALE * uv*mat2(1.0,0.0,-SKEW,1.0) - SKEW*vec2(0.0,12.0);
        
        // Shimmer rays along y-axis
        float rays = cos(SHIM_SPEED*iTime+p.y*.41+2.*sin(p.y*.27+SHIM_SPEED*iTime));
        // Compute normalized shimmer value
        float shim = smoothstep(SHIM_START, SHIM_END, rays);
        
        // Back face coordinates
        vec2 b = p + vec2(0.0,CURVE)/(6.0*QT+p.x);
        // Use our custom fwidth approximation
        vec2 bw = myfwidth(b);
        
        // Front face coordinates
        p = SCALE * uv*mat2(1.0,0.0,SKEW,1.0) + SKEW*vec2(0.0,12.0);
        
        vec2 f = p - vec2(0.0,CURVE)/(6.0*QT+p.x);
        // Use our custom fwidth approximation
        vec2 fw = myfwidth(f);
        
        // Checker alpha (alternate rows) - using custom tanh
        float walletAlpha = (sin(b.x*CHECK_WIDTH+CHECK_SPEED*iTime+QT*sign(sin(b.y)))
        +1.2*mytanh(CHECK_START - CHECK_GRAD*b.x));
        
        // Back face alpha
        walletAlpha = clamp(walletAlpha/bw.x*0.5, 0.0, 1.0); // Checkerboard
        walletAlpha *= clamp((T-max(abs(b.y),-f.y))/bw.y*0.5, 0.0, 1.0); // Vertical cutoff
        walletAlpha *= clamp((6.0*QT-abs(b.x))/bw.x*0.5, 0.0, 1.0); // Horizontal cutoff
        
        // Blend in back face (with row gradient)
        float shade = 0.7 * exp(ceil(-b.y/HT)*0.15);
        col = mix(col, COLOR * shade, walletAlpha);
        alpha = max(alpha, walletAlpha);
        
        // Front face alpha
        walletAlpha = 1.0;
        walletAlpha *= clamp((T-max(abs(f.y),b.y))/fw.y*0.5,0.0,1.0);
        walletAlpha *= clamp((5.0*QT-abs(f.x+QT))/fw.x*0.5,0.0,1.0);
        
        // Blend in front face:
        // Base shininess
        float w = SHINE * clamp((f.x+f.y-1.0)/(fw.x+fw.y)*0.5, 0.0, 1.0) + 0.1*shim + 0.02*f.y;
        // Distance to rectangle chip - using a simpler distance calculation
        float d = length((f - clamp(f, vec2(1.5,-1.3), vec2(3.5,1.3))));
        // Scale distance by our custom width approximation
        d = d / (fw.x + fw.y);
        // Blend in chip
        w += (0.9-0.08*f.y*shim-w) * clamp(5.0 - 0.5*d, 0.0, 1.0);
        // White top border
        w += (1.0-w) * clamp((f.y-.9*T) / fw.y * 0.5, 0.0, 1.0);
        // Blend in colors
        vec3 front = mix(COLOR, vec3(1.0), w);
        col = mix(col, front, walletAlpha);
        alpha = max(alpha, walletAlpha);
        
        // Get star brightness
        float star = STAR_SIZE * smoothstep(STAR_START, STAR_END, -cos(STAR_FREQ*iTime));
        // Star coordinates
        vec2 sp = uv+STAR_RAD*cos(STAR_SPIN*iTime+vec2(0.0,11.0));
        // Apply exponent
        sp = pow(abs(sp)+0.05, vec2(STAR_EXP));
        // Attenuate with exponential distance
        star /= pow(dot(sp,sp), 1.0/STAR_EXP);
        
        // Blend in star
        col += (1.0-col)*star;
        alpha = max(alpha, star * 0.7);
        
        gl_FragColor = vec4(col, alpha);
      }
    `;

    // Create and compile shaders
    const vertexShader = gl.createShader(gl.VERTEX_SHADER);
    gl.shaderSource(vertexShader, vertexShaderSource);
    gl.compileShader(vertexShader);
    
    const fragmentShader = gl.createShader(gl.FRAGMENT_SHADER);
    gl.shaderSource(fragmentShader, fragmentShaderSource);
    gl.compileShader(fragmentShader);
    
    // Check for shader compilation errors
    if (!gl.getShaderParameter(vertexShader, gl.COMPILE_STATUS)) {
      console.error('Vertex shader compilation error:', gl.getShaderInfoLog(vertexShader));
      return;
    }
    
    if (!gl.getShaderParameter(fragmentShader, gl.COMPILE_STATUS)) {
      console.error('Fragment shader compilation error:', gl.getShaderInfoLog(fragmentShader));
      return;
    }
    
    // Create and link shader program
    const shaderProgram = gl.createProgram();
    gl.attachShader(shaderProgram, vertexShader);
    gl.attachShader(shaderProgram, fragmentShader);
    gl.linkProgram(shaderProgram);
    
    // Check for program linking errors
    if (!gl.getProgramParameter(shaderProgram, gl.LINK_STATUS)) {
      console.error('Program linking error:', gl.getProgramInfoLog(shaderProgram));
      return;
    }
    
    // Setup positions (full screen quad)
    const positions = [
      -1.0, -1.0,
       1.0, -1.0,
       1.0,  1.0,
      -1.0,  1.0,
    ];
    
    const textureCoordinates = [
      0.0, 0.0,
      1.0, 0.0,
      1.0, 1.0,
      0.0, 1.0,
    ];
    
    const indices = [
      0, 1, 2,
      0, 2, 3,
    ];
    
    // Set up buffers
    const positionBuffer = gl.createBuffer();
    gl.bindBuffer(gl.ARRAY_BUFFER, positionBuffer);
    gl.bufferData(gl.ARRAY_BUFFER, new Float32Array(positions), gl.STATIC_DRAW);
    
    const textureCoordBuffer = gl.createBuffer();
    gl.bindBuffer(gl.ARRAY_BUFFER, textureCoordBuffer);
    gl.bufferData(gl.ARRAY_BUFFER, new Float32Array(textureCoordinates), gl.STATIC_DRAW);
    
    const indexBuffer = gl.createBuffer();
    gl.bindBuffer(gl.ELEMENT_ARRAY_BUFFER, indexBuffer);
    gl.bufferData(gl.ELEMENT_ARRAY_BUFFER, new Uint16Array(indices), gl.STATIC_DRAW);
    
    // Get uniform and attribute locations
    const programInfo = {
      program: shaderProgram,
      attribLocations: {
        vertexPosition: gl.getAttribLocation(shaderProgram, 'aVertexPosition'),
        textureCoord: gl.getAttribLocation(shaderProgram, 'aTextureCoord'),
      },
      uniformLocations: {
        iResolution: gl.getUniformLocation(shaderProgram, 'iResolution'),
        iTime: gl.getUniformLocation(shaderProgram, 'iTime'),
      },
    };
    
    // Animation setup
    let startTime = performance.now();
    let animationFrameId;
    
    const render = () => {
      const currentTime = performance.now();
      const elapsedTime = (currentTime - startTime) / 1000; // Convert to seconds
      
      // Clear canvas with transparency
      gl.clearColor(0.0, 0.0, 0.0, 0.0);
      gl.clear(gl.COLOR_BUFFER_BIT);
      
      // Enable blending for transparency
      gl.enable(gl.BLEND);
      gl.blendFunc(gl.SRC_ALPHA, gl.ONE_MINUS_SRC_ALPHA);
      
      // Use the shader program
      gl.useProgram(programInfo.program);
      
      // Set uniforms
      gl.uniform2f(programInfo.uniformLocations.iResolution, canvas.width, canvas.height);
      gl.uniform1f(programInfo.uniformLocations.iTime, elapsedTime);
      
      // Set vertex positions
      gl.bindBuffer(gl.ARRAY_BUFFER, positionBuffer);
      gl.vertexAttribPointer(
        programInfo.attribLocations.vertexPosition,
        2, // 2 components per vertex
        gl.FLOAT,
        false,
        0,
        0
      );
      gl.enableVertexAttribArray(programInfo.attribLocations.vertexPosition);
      
      // Set texture coordinates
      gl.bindBuffer(gl.ARRAY_BUFFER, textureCoordBuffer);
      gl.vertexAttribPointer(
        programInfo.attribLocations.textureCoord,
        2, // 2 components per vertex
        gl.FLOAT,
        false,
        0,
        0
      );
      gl.enableVertexAttribArray(programInfo.attribLocations.textureCoord);
      
      // Bind indices and draw
      gl.bindBuffer(gl.ELEMENT_ARRAY_BUFFER, indexBuffer);
      gl.drawElements(
        gl.TRIANGLES,
        6, // 6 vertices (2 triangles)
        gl.UNSIGNED_SHORT,
        0
      );
      
      // Request next frame
      animationFrameId = requestAnimationFrame(render);
    };
    
    // Start animation
    render();
    
    // Cleanup on unmount
    return () => {
      resize.unobserve(observing);
      window.removeEventListener('resize', setCanvasSize);
      
      // Cancel animation frame
      if (animationFrameId) {
        cancelAnimationFrame(animationFrameId);
      }
      
      // Delete WebGL resources
      gl.deleteProgram(shaderProgram);
      gl.deleteShader(vertexShader);
      gl.deleteShader(fragmentShader);
      gl.deleteBuffer(positionBuffer);
      gl.deleteBuffer(textureCoordBuffer);
      gl.deleteBuffer(indexBuffer);
    };
  }, []);
  
  return (
    <div ref={containerRef} style={{ position: 'absolute', top: 0, left: 0, width: '100%', height: '100%', zIndex: 999999999}}>
      <canvas ref={canvasRef} style={{width: '100%', height: '100%', background: 'transparent'}} />
    </div>
  );
};

export default WalletShader;