import * as React from "react";
import * as ReactI18Next from "react-i18next";

import * as Languages from "./languages";
import * as State from './state';

import i18next from "i18next";

export const i18nInstance = i18next.createInstance();

i18nInstance
  .use(ReactI18Next.initReactI18next).init({});

export const localize = (language) => (
  localize[language] = localize[language] || fetch(`/static/translations/${language}/global.json`, {
    method: "GET",
    headers: {
      'Content-Type': 'application/json'
    }
  })
  .then(res => res.json())
  .then(res => {
    delete localize[language];
    i18nInstance.addResourceBundle(language, 'global', res, true, true);
    return i18nInstance.changeLanguage(language);
  })
);

export const useLanguage = () => {
  const [languageLoading, setLanguageLoading] = State.useAtom('i18n.languageLoading', '');
  const [language, setLanguage] = State.useAtom('i18n.language', localStorage.defaultDashboardLanguage || Languages.DEFAULT_LANGUAGE);
  React.useEffect(() => {
    if (language && !i18nInstance.hasResourceBundle(language, 'global') && language !== languageLoading) {
      setLanguageLoading(language);
      localize(language).then(() => {
        setLanguageLoading(null);
      });
    }
  }, [language, languageLoading]);
  return [language, setLanguage, languageLoading];
};