export const DEFAULT_LANGUAGE = (
  ((typeof navigator !== 'undefined') && (navigator.languages || []).concat([navigator.language]))
    .filter(lng => lng)
    .map(lng => lng.split('-')[0])[0]
  || 'en').toLowerCase();

export const LANGUAGES = [
  ["English", "en", "Eng"],
  ["中文", "zh", "中文"],
  ["Français", "fr", "Fra"],
  ["Español", "es", "Esp"],
  ["日本語", "jp", "Jpn"],
  ["Italiano", "it", "Ita"],
  ["русский", "ru", "Rus"],
  ["Deutsche", "de", "Deu"],
  ["हिन्दी", "hi", "Hin"],
  ["فارسی", "fa", "Far"],
  ["Magyar", "hu", "Mag"],
  ["Português", "pt", "Por"],
  ["한국어", "ko", "Kor"]];

export const LANGUAGE_LOOKUP = LANGUAGES.reduce((acc, language) => ({
  ...acc,
  [language[1]]: language
}), {});