import React from "react";
import ReactDOM from "react-dom/client";
import Root from "./root";

import * as Client from "./client";

import 'overlayscrollbars/overlayscrollbars.css';

import './index.scss';

window.CHECKOUT = (import.meta.env.REACT_APP_USE_ORIONJS_CHECKOUT) ? new Promise((resolve) => {
  const script = document.createElement('script');
  script.src = `${import.meta.env.REACT_APP_CHECKOUT_URL}/static/js/checkout.js`;
  script.addEventListener('load', resolve);
  document.head.appendChild(script);
}) : null;

window.CLIENT = Client;

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
    <Root />
  </React.StrictMode>
);