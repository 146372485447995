import * as React from "react";
import * as ReactRouter from "react-router-dom";

import * as i18n from "./i18n";
import * as Languages from "./languages";

import * as State from "./state";
import * as Client from "./client";

import WalletShader from "./pages/components/shaders/wallet";

import { ReactComponent as LoaderAnimation } from "./svg/loader.svg";

import styles from "./root.module.scss";

const handleError = () => {
  document.body.dispatchEvent(new CustomEvent('error', { detail: { reload: true } }));
  return {
    default: () => (
      <div className={styles.loader}>
        {(window.localStorage.useShaderlessLoader) ? (
          <LoaderAnimation />
        ) : (
          <div className={styles.shader}>
            <WalletShader />
          </div>
        )}
      </div>
    )
  };
};

const App = React.lazy(() => import('./pages/app').catch(handleError));
const AppBalances = React.lazy(() => import('./pages/app/balances').catch(handleError));
const AppCoins = React.lazy(() => import('./pages/app/coins').catch(handleError));
const AppInvoicing = React.lazy(() => import('./pages/app/invoicing').catch(handleError));
const AppTransactions = React.lazy(() => import('./pages/app/transactions').catch(handleError));
const AppIntegrations = React.lazy(() => import('./pages/app/integrations').catch(handleError));
const AppButtons = React.lazy(() => import('./pages/app/buttons').catch(handleError));
const AppEarning = React.lazy(() => import('./pages/app/earning').catch(handleError));
const AppCards = React.lazy(() => import('./pages/app/cards').catch(handleError));
const AppESims = React.lazy(() => import('./pages/app/esims').catch(handleError));
const AppGifts = React.lazy(() => import('./pages/app/gifts').catch(handleError));
const AppPayout = React.lazy(() => import('./pages/app/payout').catch(handleError));
const AppProfile = React.lazy(() => import('./pages/app/profile').catch(handleError));
const AppBusiness = React.lazy(() => import('./pages/app/business').catch(handleError));
const AppDisabled = React.lazy(() => import('./pages/app/disabled').catch(handleError));
const AppSettings = React.lazy(() => import('./pages/app/settings').catch(handleError));
const AppNotifications = React.lazy(() => import('./pages/app/notifications').catch(handleError));
const Invoice = React.lazy(() => import('./pages/invoice').catch(handleError));
const Errors = React.lazy(() => import('./pages/errors').catch(handleError));
const Refund = React.lazy(() => import('./pages/refund').catch(handleError));
const Support = React.lazy(() => import('./pages/support').catch(handleError));
const Home = React.lazy(() => import('./pages/home').catch(handleError));

export const Login = () => {
  const [language] = i18n.useLanguage();

  const ref = React.useRef();

  React.useEffect(() => {
    if (!ref.current) {
      localStorage.setItem('firstLogin', 'true');
      localStorage.setItem('disclaimerAccepted', 'false');
      ref.current = Client.login(language);
    }
  });

  return (<></>);
};

export const Register = () => {
  const [language] = i18n.useLanguage();

  const ref = React.useRef();

  React.useEffect(() => {
    if (!ref.current) {
      localStorage.setItem('firstLogin', 'true');
      localStorage.setItem('disclaimerAccepted', 'false');
      ref.current = Client.register(language);
    }
  });

  return (<></>);
};

export const Logout = () => {
  const [language] = i18n.useLanguage();

  const ref = React.useRef();

  React.useEffect(() => {
    if (!ref.current) {
      ref.current = Client.logout(language);
    }
  });

  return (<></>);
};

export const Callback = () => {
  const [language] = i18n.useLanguage();

  React.useEffect(() => {
    if (Client.callback()) {
      window.location.href = `/${language}/app`;
    }
  });

  return (<></>);
};

export const Loader = () => {
  return (
    <div className={styles.loader}>
      {(window.localStorage.useShaderlessLoader) ? (
        <LoaderAnimation />
      ) : (
        <div className={styles.shader}>
          <WalletShader />
        </div>
      )}
    </div>
  );
};

export const AppLoader = () => {
  return (
    <div className={styles.fixed}>
      <div className={styles.loader}>
        {(window.localStorage.useShaderlessLoader) ? (
          <LoaderAnimation />
        ) : (
          <div className={styles.shader}>
            <WalletShader />
          </div>
        )}
      </div>
    </div>
  );
};

export const Routes = () => {
  const navigate = ReactRouter.useNavigate();
  const location = ReactRouter.useLocation();
  const [language, setLanguage] = i18n.useLanguage();
  const [profile, profileLoading] = State.useUserProfile();
  const [envConfig, envConfigLoading] = State.useEnvConfig();
  const statusLoading = profileLoading || envConfigLoading;

  React.useEffect(() => {
    if (location.pathname !== '/callback') {
      const lng = (location.pathname !== '/') ? location.pathname.split('/')[1] : i18n?.i18nInstance?.language || localStorage.defaultDashboardLanguage || Languages.DEFAULT_LANGUAGE;
      if ((lng !== language) || (!Languages.LANGUAGE_LOOKUP[lng])) {
        if ((lng.length === 2) && (Languages.LANGUAGE_LOOKUP[lng])) {
          i18n?.localize(lng).then(() => {
            localStorage.setItem('defaultDashboardLanguage', lng);
            setLanguage(lng);
          }).catch(() => {
            navigate(`/${language || Languages.DEFAULT_LANGUAGE}/${location.pathname.split('/').slice(2).join('/')}`);
          });
        } else if (lng.length === 2) {
          navigate(`/${Languages.DEFAULT_LANGUAGE}/${location.pathname.split('/').slice(2).join('/')}`);
        } else {
          navigate(`/${language || Languages.DEFAULT_LANGUAGE}/${location.pathname.split('/').slice(1).join('/')}`);
        }
      } else if (location.pathname.startsWith(`/${language}/dashboard`)) {
        navigate(`/${[language, 'app'].concat(location.pathname.split('/').slice(3)).join('/')}`);
      } else if (location.pathname === `/${language}/app`) {
        navigate(`/${language}/app/balances`);
      }
    }
  }, [location.pathname, language]);

  return (
    <ReactRouter.Routes>
      <ReactRouter.Route path="/callback" element={<Callback />} />
      <ReactRouter.Route path="/:language/login" element={<Login />} />
      <ReactRouter.Route path="/:language/register" element={<Register />} />
      <ReactRouter.Route path="/:language/logout" element={<Logout />} />
      <ReactRouter.Route path="/:language/auth/redirect" element={<Login />} />
      <ReactRouter.Route path="/:language/refunds/:refundid/:tag" element={<React.Suspense fallback={<Loader />}><Refund /></React.Suspense>} />
      <ReactRouter.Route path="/:language/invoices/:invoiceid" element={<React.Suspense fallback={<Loader />}><Invoice /></React.Suspense>} />
      <ReactRouter.Route path="/:language/app" element={<React.Suspense fallback={<Loader />}><App /></React.Suspense>}>
        <ReactRouter.Route path="/:language/app/notifications" element={<React.Suspense fallback={<AppLoader />}><AppNotifications /></React.Suspense>} />
        <ReactRouter.Route path="/:language/app/settings" element={<React.Suspense fallback={<AppLoader />}><AppSettings /></React.Suspense>} />
        <ReactRouter.Route path="/:language/app/payout" element={statusLoading ? <AppLoader /> : <React.Suspense fallback={<AppLoader />}>{(envConfig?.name?.toLowerCase()?.indexOf('sandbox') >= 0) ? <AppLoader /> : <AppPayout />}</React.Suspense>} />
        <ReactRouter.Route path="/:language/app/cards" element={statusLoading ? <AppLoader /> : <React.Suspense fallback={<AppLoader />}>{(envConfig?.featuresEnabled?.phaze !== true) ? <AppDisabled /> : (envConfig?.name?.toLowerCase()?.indexOf('sandbox') >= 0) ? <AppLoader /> : <AppCards />}</React.Suspense>} />
        <ReactRouter.Route path="/:language/app/earning" element={statusLoading ? <AppLoader /> : <React.Suspense fallback={<AppLoader />}>{(envConfig?.featuresEnabled?.coinChangeYield !== true) ? <AppDisabled /> : (envConfig?.name?.toLowerCase()?.indexOf('sandbox') >= 0) ? <AppLoader /> : <AppEarning />}</React.Suspense>} />
        <ReactRouter.Route path="/:language/app/esims" element={statusLoading ? <AppLoader /> : <React.Suspense fallback={<AppLoader />}>{(envConfig?.featuresEnabled?.esim !== true) ? <AppDisabled /> : (envConfig?.name?.toLowerCase()?.indexOf('sandbox') >= 0) ? <AppLoader /> : <AppESims />}</React.Suspense>} />
        <ReactRouter.Route path="/:language/app/gifts" element={statusLoading ? <AppLoader /> : <React.Suspense fallback={<AppLoader />}>{(envConfig?.featuresEnabled?.phaze !== true) ? <AppDisabled /> : (envConfig?.name?.toLowerCase()?.indexOf('sandbox') >= 0) ? <AppLoader /> : <AppGifts />}</React.Suspense>} />
        <ReactRouter.Route path="/:language/app/profile" element={statusLoading ? <AppLoader /> : <React.Suspense fallback={<AppLoader />}>{(profile?.verificationType === 'personal:kyc') ? <AppBusiness /> : <AppProfile />}</React.Suspense>} />
        <ReactRouter.Route path="/:language/app/integrations" element={statusLoading ? <AppLoader /> : <React.Suspense fallback={<AppLoader />}>{(profile?.verificationType === 'personal:kyc') ? <AppBusiness /> : <AppIntegrations />}</React.Suspense>} />
        <ReactRouter.Route path="/:language/app/buttons" element={statusLoading ? <AppLoader /> : <React.Suspense fallback={<AppLoader />}>{(profile?.verificationType === 'personal:kyc') ? <AppBusiness /> : <AppButtons />}</React.Suspense>} />
        <ReactRouter.Route path="/:language/app/invoicing" element={statusLoading ? <AppLoader /> : <React.Suspense fallback={<AppLoader />}>{(profile?.verificationType === 'personal:kyc') ? <AppBusiness /> : <AppInvoicing />}</React.Suspense>} />
        <ReactRouter.Route path="/:language/app/invoicing/:invoiceid" element={statusLoading ? <AppLoader /> : <React.Suspense fallback={<AppLoader />}>{(profile?.verificationType === 'personal:kyc') ? <AppBusiness /> : <AppInvoicing />}</React.Suspense>} />
        <ReactRouter.Route path="/:language/app/coins" element={statusLoading ? <AppLoader /> : <React.Suspense fallback={<AppLoader />}>{(profile?.verificationType === 'personal:kyc') ? <AppBusiness /> : <AppCoins />}</React.Suspense>} />
        <ReactRouter.Route path="/:language/app/business" element={<React.Suspense fallback={<AppLoader />}><AppBusiness /></React.Suspense>} />
        <ReactRouter.Route path="/:language/app/transactions" element={<React.Suspense fallback={<AppLoader />}><AppTransactions /></React.Suspense>} />
        <ReactRouter.Route path="/:language/app/balances" element={<React.Suspense fallback={<AppLoader />}><AppBalances /></React.Suspense>} />
      </ReactRouter.Route>
      <ReactRouter.Route path="/:language/errors/:error" element={<React.Suspense fallback={<Loader />}><Errors /></React.Suspense>} />
      <ReactRouter.Route path="/:language/checkout-refund/:refundid/:tag" element={<React.Suspense fallback={<Loader />}><Refund /></React.Suspense>} />
      <ReactRouter.Route path="/:language/support" element={<React.Suspense fallback={<Loader />}><Support /></React.Suspense>} />
      <ReactRouter.Route path="/:language" element={<React.Suspense fallback={<Loader />}><Home /></React.Suspense>} />
      <ReactRouter.Route path="/" element={<React.Suspense fallback={<Loader />}><Home /></React.Suspense>} />
    </ReactRouter.Routes>
  );
};

export const Root = () => {
  return (
    <ReactRouter.BrowserRouter basename="/">
      <Routes />
    </ReactRouter.BrowserRouter>
  );
};

export default Root;